import React from 'react';
import { useTranslation } from 'react-i18next';
import ImageGallery from './ImageGallery';
import galleryImages from '../data/galleryImages.json';

function Gallery() {
    const { t, i18n } = useTranslation();

    const images = galleryImages.images;
    
  return (
    <div className="bg-customColor">
      <div className="px-6 py-12 sm:px-6 sm:py-16 lg:px-8">
      <h2 className="mt-2 text-2xl text-center font-light tracking-normal uppercase text-mainColor sm:text-3xl">{t('Vaata pilte')}</h2>
        <div className="mx-auto max-w-7xl text-center w-full">
        <ImageGallery images={images} />
        <a href={`/contact?lang=${i18n.language}`}
           type="button"
           className="rounded-sm bg-mainColor mt-12 md:my-0 md:mt-8 sm:my-0 sm:mt-8 px-3 py-2 text-sm font-semibold text-customColor shadow-sm hover:bg-green-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-mainColor"
           >
            {t('Broneeri kohe')}
          </a>
        </div>
      </div>
    </div>
  )
};

export default Gallery