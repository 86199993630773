import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ImageGallery = ({ images }) => {
  const [currentImage, setCurrentImage] = useState(images[0]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    focusOnSelect: true,
    afterChange: index => setCurrentImage(images[index]),
    centerMode: true,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div className="image-gallery p-6 rounded-sm mt-12">
      <div className="main-image">
        <img src={currentImage} alt="Main" className="main-image" />
      </div>
      <Slider {...settings}>
        {images.map((img, index) => (
          <div key={index} className="thumbnail">
            <img src={img} alt={`Thumbnail ${index}`} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageGallery;
