import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Example() {
    const { t } = useTranslation();

    return (
        <footer className="bg-customColor">
        <div className="mx-auto max-w-7xl px-6 py-10 md:flex md:items-center md:justify-between lg:px-8">
        <div className="mb-4 md:order-1 md:mt-0">
            <p className="text-center text-xs leading-5 text-mainColor">
              V 1.0.1 &copy; 2024 Powered by <a href="https://zero2one.ee" className='text-mainColor font-semibold'>zero2one</a>. {t('Kõik õigused kaitstud')}.
            </p>
          </div>
          <a href="/" className='flex justify-center md:order-2'>
           <img src="/img/zero2one-logo.svg" alt="Logo" className='w-12 lg:w-16' />
          </a>
          
        </div>
      </footer>
    )
  }