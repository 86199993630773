// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

.flex-grow {
  flex-grow: 1;
}

footer {
  background-color: #e4d9c6;
  padding: 1rem;
  text-align: center;
}

.image-gallery {
  background-color: #e4d9c6;
  width: 100%;
  margin: 20px;
  text-align: center;
  border-radius: 5px;
}
.main-image {
  margin-bottom: 20px;
}
.main-image img {
  width: 100%;
  height: auto;
  max-height: 500px;
  border-radius: 5px;
  object-fit: contain;
}
.thumbnail {
  padding: 5px;
}
.thumbnail img {
  width: 200px;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
  transition: transform 0.2s ease;
  border-radius: 5px;
}
.thumbnail img:hover {
  transform: scale(1.1);
}

.language-switcher-img:hover {
  transform: scale(1.2);
  transition: transform 0.2s ease;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,YAAY;AACd;AACA;EACE,YAAY;EACZ,aAAa;EACb,iBAAiB;EACjB,eAAe;EACf,+BAA+B;EAC/B,kBAAkB;AACpB;AACA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;EACrB,+BAA+B;AACjC","sourcesContent":[".App {\n  text-align: center;\n}\n\n.flex-grow {\n  flex-grow: 1;\n}\n\nfooter {\n  background-color: #e4d9c6;\n  padding: 1rem;\n  text-align: center;\n}\n\n.image-gallery {\n  background-color: #e4d9c6;\n  width: 100%;\n  margin: 20px;\n  text-align: center;\n  border-radius: 5px;\n}\n.main-image {\n  margin-bottom: 20px;\n}\n.main-image img {\n  width: 100%;\n  height: auto;\n  max-height: 500px;\n  border-radius: 5px;\n  object-fit: contain;\n}\n.thumbnail {\n  padding: 5px;\n}\n.thumbnail img {\n  width: 200px;\n  height: 100px;\n  object-fit: cover;\n  cursor: pointer;\n  transition: transform 0.2s ease;\n  border-radius: 5px;\n}\n.thumbnail img:hover {\n  transform: scale(1.1);\n}\n\n.language-switcher-img:hover {\n  transform: scale(1.2);\n  transition: transform 0.2s ease;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
