import React from 'react';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


function About() {
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const lang = searchParams.get('lang');

  useEffect(() => {
    if (lang) i18n.changeLanguage(lang);
  }, [lang, i18n]);

  
  return (
    <div className="overflow-hidden bg-customColor pt-12 sm:pt-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 mb-12">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="mt-2 text-2xl font-light tracking-normal uppercase text-mainColor sm:text-3xl">{t('Puhkus keset lummavat looduskaitseala')}</h2>
              <p className="my-6 text-base text-justify leading-8 text-mainColor">

              {t('Rahulik pelgupaik Pärnu lahe ääres asub 1,5 hektari suurusel kinnistul 100 meetri pikkuse rannajoonega, pakkudes loodusesõpradele idüllilist puhkust ja telkimisvõimalust. Samuti on oodatud lemmikloomad.')}
              <br />
              {t('Vaid vähem kui kilomeetri kaugusel Munalaiu sadamast ja vaid 30 minuti kaugusel auto või bussiga Pärnust, kahekorruseline palkmaja sisaldab nelja tuba, kust avanevad imelised vaated merele.')} 
              <br />
              {t('Majas on elektripliit, külmik ja tahkekütusega kamin. Loodussõbralik kuivkäimla asub maja lähedal.')}
              <br />
              {t('Lähedalasuv Munalaiu sadam pakub juurde erinevaid mugavusi. Sealt on võimalik hankida joogivett, kasutada dušši ning pesupesemise võimalust.')}
              <br />
              {t('Naudi rannamatku, lummavaid päikeseloojanguid ja imelist rannikukeskkonda. Võta kaasa binokkel, et jälgida lindude tegevusi. Asukoht on ideaalne neile, kes tunnevad end mugavalt ebatasasel maastikul.')}
              <br />
              <br />
              {t('Täiuslik paik Romantilise Rannatee vahetus läheduses pakub rahulikku atmosfääri et saaksid veeta aega raamatu seltsis, mõtiskleda ja leida elus rõõmu ja uusi väljavaateid.')}
              <br />
              <br />
              {t('Broneeri oma peatus täna ja loo unustamatuid mälestusi mere ääres.')}
              </p>
              <div className="mt-12 flex items-center justify-start gap-x-4">
                <a href={`/contact?lang=${i18n.language}`}
                  type="button"
                  className="rounded-sm bg-mainColor px-2.5 py-1.5 text-sm font-semibold text-customColor shadow-sm hover:bg-green-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-mainColor"
                >
                  {t('Broneeri kohe')}
                </a>

                <a href={`/gallery?lang=${i18n.language}`}
                  type="button"
                  className="rounded-sm bg-customColor px-2.5 py-1.5 text-sm font-semibold text-mainColor shadow-sm ring-1 ring-inset ring-mainColor hover:text-green-900"
                >
                  {t('Galerii')}
                </a >
              </div>
              <div className='mt-6'>
              <a href="https://www.kv.ee/3623852" className='text-mainColor underline hover:text-green-900'>{t('Vaata ka siia')}</a>
              </div>
              
            </div>
            </div>
            
          <img
            src="/img/poster-image.jpg"
            alt="Product screenshot"
            className="w-full h-[36rem] max-w-none rounded-sm shadow-xl sm:w-[57rem] md:-ml-4 lg:-ml-0 mt-8"
          />
        </div>
      </div>
    </div>
  )
}

export default About