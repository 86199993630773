import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
ee: {
     translation: {
      "Esileht": "Esileht",
      "Tutvustus": "Tutvustus",
      "Kontakt": "Kontakt",
      "Tere tulemast Linnujaama": "Tere tulemast Linnujaama",
      "Mereäärne vaikus": "Mereäärne vaikus",
      "Tule lõõgastu ja naudi rahulikku puhkust looduses.": "Tule lõõgastu ja naudi rahulikku puhkust looduses.",
      "Koge mere õrna ilu ja rahuliku pelgupaiga vaikset õhkkonda.": "Koge mere õrna ilu ja rahuliku pelgupaiga vaikset õhkkonda.",
      "Avasta rannikuäärne rahu eraldatud looduse varjus": "Avasta rannikuäärne rahu eraldatud looduse varjus",
      "Broneeri kohe": "Broneeri kohe",
      "Vaata lähemalt": "Vaata lähemalt",
      "Puhkus keset lummavat looduskaitseala": "Puhkus keset lummavat looduskaitseala",
      "Saada meile sõnum!": "Saada meile sõnum!",
      "Saada sõnum": "Saada sõnum",
      "Nimi": "Nimi",
      "Email": "Email",
      "Telefon": "Telefon",
      "Sisesta tekst": "Sisesta tekst",
      "Rahulik pelgupaik Pärnu lahe ääres asub 1,5 hektari suurusel kinnistul 100 meetri pikkuse rannajoonega, pakkudes loodusesõpradele idüllilist puhkust ja telkimisvõimalust. Samuti on oodatud lemmikloomad.": "Rahulik pelgupaik Pärnu lahe ääres asub 1,5 hektari suurusel kinnistul 100 meetri pikkuse rannajoonega, pakkudes loodusesõpradele idüllilist puhkust ja telkimisvõimalust. Samuti on oodatud lemmikloomad.",
      "Vaid vähem kui kilomeetri kaugusel Munalaiu sadamast ja vaid 30 minuti kaugusel auto või bussiga Pärnust, kahekorruseline palkmaja sisaldab nelja tuba, kust avanevad imelised vaated merele.": "Vaid vähem kui kilomeetri kaugusel Munalaiu sadamast ja vaid 30 minuti kaugusel auto või bussiga Pärnust, kahekorruseline palkmaja sisaldab nelja tuba, kust avanevad imelised vaated merele.",
      "Majas on elektripliit, külmik ja tahkekütusega kamin. Loodussõbralik kuivkäimla asub maja lähedal.": "Majas on elektripliit, külmik ja tahkekütusega kamin. Loodussõbralik kuivkäimla asub maja lähedal.",
      "Lähedalasuv Munalaiu sadam pakub juurde erinevaid mugavusi. Sealt on võimalik hankida joogivett, kasutada dušši ning pesupesemise võimalust.": "Lähedalasuv Munalaiu sadam pakub juurde erinevaid mugavusi. Sealt on võimalik hankida joogivett, kasutada dušši ning pesupesemise võimalust.",
      "Naudi rannamatku, lummavaid päikeseloojanguid ja imelist rannikukeskkonda. Võta kaasa binokkel, et jälgida lindude tegevusi. Asukoht on ideaalne neile, kes tunnevad end mugavalt ebatasasel maastikul.": "Naudi rannamatku, lummavaid päikeseloojanguid ja imelist rannikukeskkonda. Võta kaasa binokkel, et jälgida lindude tegevusi. Asukoht on ideaalne neile, kes tunnevad end mugavalt ebatasasel maastikul.",
      "Täiuslik paik Romantilise Rannatee vahetus läheduses pakub rahulikku atmosfääri et saaksid veeta aega raamatu seltsis, mõtiskleda ja leida elus rõõmu ja uusi väljavaateid." :"Täiuslik paik Romantilise Rannatee vahetus läheduses pakub rahulikku atmosfääri et saaksid veeta aega raamatu seltsis, mõtiskleda ja leida elus rõõmu ja uusi väljavaateid.",
      "Broneeri oma peatus täna ja loo unustamatuid mälestusi mere ääres.": "Broneeri oma peatus täna ja loo unustamatuid mälestusi mere ääres.",
      "Kas Sul on küsimusi või vajad rohkem infot?": "Kas Sul on küsimusi või vajad rohkem infot?",
      "Meil oleks hea meel Sinust kuulda": "Meil oleks hea meel Sinust kuulda",
      "Täida kontaktivorm ja me võtame Sinuga esimesel võimalusel ühendust.": "Täida kontaktivorm ja me võtame Sinuga esimesel võimalusel ühendust.",
      "Sinu täiuslik puhkus ootab!": "Sinu täiuslik puhkus ootab!",
      "Vaata ka siia": "Vaata ka siia",
      "Kõik õigused kaitstud": "Kõik õigused kaitstud",
      "Vaata pilte": "Vaata pilte",
      "Galerii": "Galerii",
      "Palun sisesta korrekne email.": "Palun sisesta korrekne email.",
      "Palun sisesta korrekne telefoninumber.": "Palun sisesta korrekne telefoninumber.",
      "Sinu sõnum on saadetud!": "Sinu sõnum on saadetud!",
      "Kohustuslikud väljad": "Kohustuslikud väljad"
    }
      },
            
  en: {
    translation: {
      "Esileht": "Home",
      "Tutvustus": "About",
      "Kontakt": "Contact",
      "Tere tulemast Linnujaama": "Welcome to Linnujaama",
      "Mereäärne vaikus": "Seaside Sanctuary",
      "Tule lõõgastu ja naudi rahulikku puhkust looduses.": "Come relax and enjoy a peaceful getaway in nature.",
      "Koge mere õrna ilu ja rahuliku pelgupaiga vaikset õhkkonda.": "Experience the gentle beauty of the sea and the serene atmosphere of peaceful hideaway.",
      "Avasta rannikuäärne rahu eraldatud looduse varjus": "Discover tranquility by the sea in a secluded natural haven",
      "Broneeri kohe": "Book now",
      "Vaata lähemalt": "See more",
      "Puhkus keset lummavat looduskaitseala": "Vacation in an enchanting nature reserve",
      "Saada meile sõnum!": "Send us a message!",
      "Saada sõnum": "Send message",
      "Nimi": "Name",
      "Email": "Email",
      "Telefon": "Phone",
      "Sisesta tekst": "Enter message",
      "Rahulik pelgupaik Pärnu lahe ääres asub 1,5 hektari suurusel kinnistul 100 meetri pikkuse rannajoonega, pakkudes loodusesõpradele idüllilist puhkust ja telkimisvõimalust. Samuti on oodatud lemmikloomad.": "A peaceful getaway on the shores of Pärnu Bay, set on a 1.5-hectare property with 100 meters of beachfront, offers a serene escape for nature lovers and camping opportunities. Pets are also welcome",
      "Vaid vähem kui kilomeetri kaugusel Munalaiu sadamast ja vaid 30 minuti kaugusel auto või bussiga Pärnust, kahekorruseline palkmaja sisaldab nelja tuba, kust avanevad imelised vaated merele.": "Located less than a kilometer from Munalaiu Harbor and just 30 minutes by car or a bus from Pärnu, two-story log house features four rooms with stunning sea view.",
      "Majas on elektripliit, külmik ja tahkekütusega kamin. Loodussõbralik kuivkäimla asub maja lähedal.": "The house is equipped with an electric stove, refrigerator, and a solid-fuel fireplace. An eco-friendly dry toilet is located nearby.",
      "Lähedalasuv Munalaiu sadam pakub juurde erinevaid mugavusi. Sealt on võimalik hankida joogivett, kasutada dušši ning pesupesemise võimalust.": "The nearby port of Munalaiu offers additional amenities. From there you can get drinking water, use a shower and do laundry.",
      "Naudi rannamatku, lummavaid päikeseloojanguid ja imelist rannikukeskkonda. Võta kaasa binokkel, et jälgida lindude tegevusi. Asukoht on ideaalne neile, kes tunnevad end mugavalt ebatasasel maastikul.": "Enjoy beach strolls, enchanting sunsets, and the tranquil coastal environment. Bring binoculars to observe bird activities. Place is ideal for those who are comfortable navigating on uneven terrain.",
      "Täiuslik paik Romantilise Rannatee vahetus läheduses pakub rahulikku atmosfääri et saaksid veeta aega raamatu seltsis, mõtiskleda ja leida elus rõõmu ja uusi väljavaateid.": "A perfect haven in the immediate vicinity of the Romantic Coastal Road offers a peaceful atmosphere so that you can spend time with a book, do bird watching, meditate and find joy and new perspectives in life.",
      "Broneeri oma peatus täna ja loo unustamatuid mälestusi mere ääres.": "Book your stay today and create unforgettable memories by the sea.",
      "Kas Sul on küsimusi või vajad rohkem infot?": "Have questions or need more information?",
      "Meil oleks hea meel Sinust kuulda": "We'd love to hear from you",
      "Täida kontaktivorm ja me võtame Sinuga esimesel võimalusel ühendust.": "Fill out the contact form, and we will get back to you as soon as possible.",
      "Sinu täiuslik puhkus ootab!": "Your perfect getaway awaits!",
      "Vaata ka siia": "See more",
      "Kõik õigused kaitstud": "All rights reserved",
      "Vaata pilte": "Explore the gallery",
      "Galerii": "Gallery",
      "Palun sisesta korrektne email.": "Please enter a valid email.",
      "Palun sisesta korrektne telefoninumber.": "Please enter a valid phone number.",
      "Sinu sõnum on saadetud!": "Your message has been sent!",
      "Kohustuslikud väljad": "Required fields",
    }
  },
  fin: {
    translation: {
      "Esileht": "Etusivu",
      "Tutvustus": "Esittely",
      "Kontakt": "Yhteystiedot",
      "Tere tulemast Linnujaama": "Tervetuloa Linnujaama",
      "Mereäärne vaikus": "Merenranta hiljaisuus",
      "Tule lõõgastu ja naudi rahulikku puhkust looduses.": "Tule rentoutumaan ja nauti rauhallisesta lomasta luonnossa.",
      "Koge mere õrna ilu ja rahuliku pelgupaiga vaikset õhkkonda.": "Koe meren herkkä kauneus ja rauhallisen turvapaikan hiljainen ilmapiiri.",
      "Avasta rannikuäärne rahu eraldatud looduse varjus": "Löydä rannikon rauha eristäytyneen luonnon suojassa.",
      "Broneeri kohe": "Varaa nyt",
      "Vaata lähemalt": "Katso lisää",
      "Puhkus keset lummavat looduskaitseala": "Loma keskellä lumoavaa luonnonsuojelualuetta",
      "Saada meile sõnum!": "Lähetä meille viesti!",
      "Saada sõnum": "Lähetä viesti",
      "Nimi": "Nimi",
      "Email": "Sähköposti",
      "Telefon": "Puhelin",
      "Sisesta tekst": "Syötä teksti",
      "Rahulik pelgupaik Pärnu lahe ääres asub 1,5 hektari suurusel kinnistul 100 meetri pikkuse rannajoonega, pakkudes loodusesõpradele idüllilist puhkust ja telkimisvõimalust. Samuti on oodatud lemmikloomad.": "Rauhallinen turvapaikka Pärnunlahden rannalla sijaitsee 1,5 hehtaarin tontilla, jolla on 100 metriä rantaviivaa, tarjoten luonnonystäville idyllisen loman ja telttailumahdollisuuden. Myös lemmikit ovat tervetulleita.",
      "Vaid vähem kui kilomeetri kaugusel Munalaiu sadamast ja vaid 30 minuti kaugusel auto või bussiga Pärnust, kahekorruseline palkmaja sisaldab nelja tuba, kust avanevad imelised vaated merele.": "Vain alle kilometrin päässä Munalaidun satamasta ja vain 30 minuutin ajomatkan päässä autolla tai bussilla Pärnusta, kaksikerroksinen hirsitalo sisältää neljä huonetta, joista avautuvat upeat näkymät merelle.",
      "Majas on elektripliit, külmik ja tahkekütusega kamin. Loodussõbralik kuivkäimla asub maja lähedal.": "Talossa on sähköliesi, jääkaappi ja kiinteän polttoaineen takka. Ympäristöystävällinen kuivakäymälä sijaitsee talon lähellä.",
      "Lähedalasuv Munalaiu sadam pakub juurde erinevaid mugavusi. Sealt on võimalik hankida joogivett, kasutada dušši ning pesupesemise võimalust.": "Lähellä oleva Munalaidun satama tarjoaa erilaisia mukavuuksia. Sieltä on mahdollista hankkia juomavettä, käyttää suihkua ja pesulapalveluita.",
      "Naudi rannamatku, lummavaid päikeseloojanguid ja imelist rannikukeskkonda. Võta kaasa binokkel, et jälgida lindude tegevusi. Asukoht on ideaalne neile, kes tunnevad end mugavalt ebatasasel maastikul.": "Nauti rantakävelyistä, lumoavista auringonlaskuista ja upeasta rannikkoalueesta. Ota mukaan kiikarit, jotta voit seurata lintujen toimintaa. Sijainti on ihanteellinen niille, jotka tuntevat olonsa mukavaksi epätasaisessa maastossa.",
      "Täiuslik paik Romantilise Rannatee vahetus läheduses pakub rahulikku atmosfääri et saaksid veeta aega raamatu seltsis, mõtiskleda ja leida elus rõõmu ja uusi väljavaateid.": "Täydellinen paikka Romanttisen Rantatien välittömässä läheisyydessä tarjoaa rauhallisen ilmapiirin, jotta voit viettää aikaa kirjan parissa, pohdiskella ja löytää iloa ja uusia näkymiä elämään.",
      "Broneeri oma peatus täna ja loo unustamatuid mälestusi mere ääres.": "Varaa oleskelusi tänään ja luo unohtumattomia muistoja meren äärellä.",
      "Kas Sul on küsimusi või vajad rohkem infot?": "Onko sinulla kysyttävää tai tarvitsetko lisätietoja?",
      "Meil oleks hea meel Sinust kuulda": "Olisi mukava kuulla sinusta",
      "Täida kontaktivorm ja me võtame Sinuga esimesel võimalusel ühendust.": "Täytä yhteydenottolomake ja otamme sinuun yhteyttä mahdollisimman pian.",
      "Sinu täiuslik puhkus ootab!": "Täydellinen lomasi odottaa!",
      "Vaata ka siia": "Katso myös tänne",
      "Kõik õigused kaitstud": "Kaikki oikeudet pidätetään",
      "Vaata pilte": "Katso kuvat",
      "Galerii": "Galleria",
      "Palun sisesta korrektne email.": "Anna kelvollinen sähköpostiosoite.",
      "Palun sisesta korrektne telefoninumber.": "Anna kelvollinen puhelinnumero.",
      "Sinu sõnum on saadetud!": "Viestisi on lähetetty!",
      "Kohustuslikud väljad": "Pakolliset kentät"
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "ee", // default language
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;