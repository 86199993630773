import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import toast, { Toaster } from 'react-hot-toast';

function Contact() {
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const lang = searchParams.get('lang');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (lang) i18n.changeLanguage(lang);
  }, [lang, i18n]);

  const handleEmailChange = (e) => {
    const emailInput = e.target.value;
    setEmail(emailInput);
    setEmailError(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailInput));
  };

  const handlePhoneChange = (e) => {
    const phoneInput = e.target.value;
    setPhone(phoneInput);
    // Simple validation for phone number (adjust regex as needed)
    setPhoneError(!/^\+?(\d{1,3})?[-. ]?\(?\d{1,3}\)?[-. ]?\d{3}[-. ]?\d{4}$/.test(phoneInput));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name && email && !emailError && message) {
      toast(t('Sinu sõnum on saadetud!'), {
        style: {
          background: '#F9F7F3',
          color: '#666648',
          border: '1px solid #666648',
          width: '300px',
          height: '50px',
          borderRadius: '5px',
          padding: '5px',
          textAlign: 'center',
        },
      });
      setName('');
      setEmail('');
      setPhone('');
      setMessage('');
    } else {
      toast.error(t('Please fill in all required fields.'), {
        style: {
          background: '#f44336',
          color: '#fff',
          border: '1px solid #fff',
          width: '300px',
          height: '100px',
          borderRadius: '5px',
          padding: '10px',
          textAlign: 'center',
        },
      });
    }
  };

  return (
    <div className="relative bg-customColor py-12 sm:py-16 ">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-customColor" />
      </div>
      <div className="relative mx-auto max-w-7xl lg:grid lg:grid-cols-5">
        <div style={{ backgroundImage: "url('/img/IMG_4.jpg')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
         }} className="px-6 py-16 lg:col-span-2 lg:px-8 lg:py-12 xl:pr-12">
          <div className="mt-4 mx-auto max-w-lg">
            <h2 className="text-2xl font-light uppercase tracking-normal text-customColor sm:text-3xl">{t('Meil oleks hea meel Sinust kuulda')}</h2>
            <p className="mt-12 text-lg leading-6 text-customColor">
            {t('Kas Sul on küsimusi või vajad rohkem infot?')}
            <br />
            {t('Saada meile sõnum!')}
            <br />
            <br />
            {t('Täida kontaktivorm ja me võtame Sinuga esimesel võimalusel ühendust.')}
            <br />
            <br />
            {t('Sinu täiuslik puhkus ootab!')}
            </p>
          </div>
        </div>
        <div className="bg-customColor px-6 py-16 lg:col-span-3 lg:px-8 lg:pt-12 xl:pl-12">
          <div className="mx-auto max-w-lg lg:max-w-none">
            <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-y-6">
              <div>
              <label htmlFor="full-name" className="block text-md font-medium text-mainColor">
                 {t('Nimi')}
                 <span className="text-red-500 ml-1">*</span>
              </label>
                <input
                  type="text"
                  name="full-name"
                  id="full-name"
                  autoComplete="name"
                  required
                  className="block w-full rounded-sm border-gray-300 px-4 py-3 placeholder-gray-400 shadow-sm focus:border-mainColor focus:ring-mainColor"
                  placeholder={t('Nimi')}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div>
              <label htmlFor="email" className="block text-md font-medium text-mainColor">
                 {t('Email')}
                 <span className="text-red-500 ml-1">*</span>
              </label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className={`block w-full rounded-sm border-gray-300 px-4 py-3 placeholder-gray-400 shadow-sm focus:border-mainColor focus:ring-mainColor ${emailError ? 'border-red-500' : ''}`}
                placeholder={t('Email')}
                value={email}
                onChange={handleEmailChange}
              />
              {emailError && <p className="text-red-500 text-xs italic">{t('Please enter a valid email.')}</p>}
            </div>
            <div>
              <label htmlFor="phone" className="block text-md font-medium text-mainColor">
                {t('Telefon')}
              </label>
              <input
                type="text"
                name="phone"
                id="phone"
                autoComplete="tel"
                className={`block w-full rounded-sm border-gray-300 px-4 py-3 placeholder-gray-400 shadow-sm focus:border-mainColor focus:ring-mainColor ${phoneError ? 'border-red-500' : ''}`}
                placeholder={t('Telefon')}
                value={phone}
                onChange={handlePhoneChange}
              />
              {phoneError && <p className="text-red-500 text-xs italic">{t('Please enter a valid phone number.')}</p>}
            </div>
              <div>
                <label htmlFor="message" className="block text-md font-medium text-mainColor">
                   {t('Sisesta tekst')}
                   <span className="text-red-500 ml-1">*</span>
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows={4}
                  required
                  className="block w-full rounded-sm border-gray-300 px-4 py-3 placeholder-gray-400 shadow-sm focus:border-mainColor focus:ring-mainColor"
                  placeholder={t('Sisesta tekst')}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>

              <p className='text-red-500 text-xs italic'>* {t('Kohustuslikud väljad')}</p>
              <div>
                <button
                  type="submit"
                  className="rounded-sm bg-mainColor lg:mt-8 px-2.5 py-1.5 text-sm font-semibold text-customColor shadow-sm hover:bg-green-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-mainColor"
                >
                  {t('Saada sõnum')}
                </button>
                <Toaster
                 position="top-center"
                 reverseOrder={false}
                
                 />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact