import React from 'react';
import { useTranslation } from 'react-i18next';

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <div className="flex space-x-4">
      <button onClick={() => changeLanguage('ee')}>
        <img className='h-4 w-auto language-switcher-img' src="/img/est.svg" alt="" />
      </button>
      <button onClick={() => changeLanguage('en')}>
        <img className='h-4 w-auto language-switcher-img' src="/img/eng.svg" alt="" />
        </button>
      <button onClick={() => changeLanguage('fin')}>
        <img className='h-4 w-auto language-switcher-img' src="/img/fin.svg" alt="" />
        </button>
    </div>
  );
}

export default LanguageSwitcher;