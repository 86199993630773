import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';

function Header() {
  const { t } = useTranslation();
  const getNavLinkClass = ({ isActive }) => isActive ? "px-4 text-green-900 hover:text-green-700 underline" : "px-4 text-mainColor hover:text-green-800";

  return (
    <header className="bg-customColor text-mainColor px-12 py-4 ">
      <div className="md:flex md:items-center md:justify-between max-w-7xl mx-auto">
        <a href="/">
          <img src="/img/logo.svg" alt="logo-img" className="w-16 h-16 mx-auto md:mx-0" />
        </a>
        
        <div className="mt-4 flex justify-center md:ml-4 md:mt-0 ">
          <NavLink to="/" className={getNavLinkClass}>{t('Esileht')}</NavLink>
          <NavLink to="/about" className={getNavLinkClass}>{t('Tutvustus')}</NavLink>
          <NavLink to="/gallery" className={getNavLinkClass}>{t('Galerii')}</NavLink>
          <NavLink to="/contact" className={getNavLinkClass}>{t('Kontakt')}</NavLink>
        </div>
        <div className='mt-4 flex items-center justify-center'>
            <LanguageSwitcher />
        </div>
        
      </div>
    </header>
  )
}

export default Header;