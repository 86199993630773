import React from 'react';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Home() {
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const lang = searchParams.get('lang');

  useEffect(() => {
    if (lang) i18n.changeLanguage(lang);
  }, [lang, i18n]);

  return (
    <div className='bg-customColor'>
      <div id='home-top' className="relative isolate overflow-hidden bg-gray-900 px-6 py-24 sm:py-32 lg:px-8">
        <img
          src="/img/linnujaama.jpg"
          alt="PosterImage"
          className="absolute inset-0 h-full w-full object-cover object-center" 
          style={{ zIndex: -1 }}
        />
        <div className="mt-20 mx-auto max-w-2xl text-center">
          <h2 className="text-4xl font-normal tracking-wide leading-8 text-customColor uppercase sm:text-6xl">{t('Tere tulemast Linnujaama')}</h2>
          <p className="mt-6 text-lg leading-8 font-light text-customColor">
            {t('Avasta rannikuäärne rahu eraldatud looduse varjus')}
          </p>
        </div>
      </div>
      <div id='home-bottom' className="bg-customColor px-6 pt-20 sm:px-6 sm:pt-24 lg:px-8">
        <div className="mx-auto max-w-7xl text-center">
          <h2 className="text-2xl font-normal tracking-normal uppercase text-mainColor sm:text-3xl">
            {t('Mereäärne vaikus')}
          </h2>
          <p className="mx-auto my-8 max-w-7xl text-lg font-light text-mainColor">
            {t('Tule lõõgastu ja naudi rahulikku puhkust looduses.')} {t('Koge mere õrna ilu ja rahuliku pelgupaiga vaikset õhkkonda.')}
          </p>
          <div className="mt-12 flex items-center justify-center gap-x-4">
            <a href={`/contact?lang=${i18n.language}`}
              type="button"
              className="rounded-sm bg-mainColor px-2.5 py-1.5 text-sm font-semibold text-customColor shadow-sm hover:bg-green-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-mainColor"
            >
              {t('Broneeri kohe')}
            </a>
            <a href={`/about?lang=${i18n.language}`}
              type="button"
              className="rounded-sm bg-customColor px-2.5 py-1.5 text-sm font-semibold text-mainColor shadow-sm ring-1 ring-inset ring-mainColor hover:text-green-900"
            >
              {t('Vaata lähemalt')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;